import(/* webpackMode: "eager" */ "/app/apps/huutokaupat-frontend/public/images/hero-background.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/huutokaupat-frontend/slices/LinkBlocks/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavItemDropdown"] */ "/app/apps/huutokaupat-frontend/slices/MainNavigationItem/NavItemDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavItemUserMenu"] */ "/app/apps/huutokaupat-frontend/slices/MainNavigationItem/NavItemUserMenu.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/huutokaupat-frontend/slices/RegistrationIconBlocks/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/huutokaupat-frontend/slices/RegistrationLinkBlocks/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/huutokaupat-frontend/src/components/Category/CategoryMenu.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/huutokaupat-frontend/src/components/Container/Container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/huutokaupat-frontend/src/components/EntryList/FrontPageEntryList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FavoritesList"] */ "/app/apps/huutokaupat-frontend/src/components/Favorites/FavoritesList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/huutokaupat-frontend/src/components/Header/HeroCountdown.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/huutokaupat-frontend/src/components/Link/LinkButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarSection","SidebarContent"] */ "/app/apps/huutokaupat-frontend/src/features/EntryPage/SidebarSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FrontPageAnalytics"] */ "/app/apps/huutokaupat-frontend/src/features/FrontPage/FrontPageAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavItem"] */ "/app/apps/huutokaupat-frontend/src/features/SiteHeader/MainNavigationItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicNextImage"] */ "/app/node_modules/.pnpm/@prismicio+next@1.7.0_@prismicio+client@7.12.0_next@14.2.17_@opentelemetry+api@1.8.0_@playwri_3xwlr67rfwhicjas3eyj3qdcqi/node_modules/@prismicio/next/dist/PrismicNextImage.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicNextLink"] */ "/app/node_modules/.pnpm/@prismicio+next@1.7.0_@prismicio+client@7.12.0_next@14.2.17_@opentelemetry+api@1.8.0_@playwri_3xwlr67rfwhicjas3eyj3qdcqi/node_modules/@prismicio/next/dist/PrismicNextLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicPreviewClient"] */ "/app/node_modules/.pnpm/@prismicio+next@1.7.0_@prismicio+client@7.12.0_next@14.2.17_@opentelemetry+api@1.8.0_@playwri_3xwlr67rfwhicjas3eyj3qdcqi/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicToolbar"] */ "/app/node_modules/.pnpm/@prismicio+react@2.9.1_@prismicio+client@7.12.0_react@18.3.1/node_modules/@prismicio/react/dist/PrismicToolbar.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.17_@opentelemetry+api@1.8.0_@playwright+test@1.48.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.17_@opentelemetry+api@1.8.0_@playwright+test@1.48.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.17_@opentelemetry+api@1.8.0_@playwright+test@1.48.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["SquaresFour","UserCircle","Star","Heart","HourglassHigh","List"] */ "/app/packages/forge-icons/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/packages/forge/dist/index.js");
