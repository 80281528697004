'use client';

import React from 'react';
import { Box, BoxProps, Heading, Link as StyledLink, Text } from '@mezzoforte/forge';
import Link from '@/components/Link/Link';
import { useFavorites } from '@/hooks/useFavorites';
import useScrollClamp from '@/hooks/useScrollClamp';
import { NAV_HEIGHT } from '@/features/SiteHeader/SiteHeader';
import { FavoriteCard } from '@/components/Favorites/FavoriteCard';

export interface FavoritesListProps {
  maxCount?: number;
}

export function FavoritesList({ maxCount = 5, ...props }: FavoritesListProps & BoxProps) {
  const {
    favorites: { isPending, isError, data },
  } = useFavorites();
  const [top] = useScrollClamp(NAV_HEIGHT);

  if (isPending || isError || !data?.entries.length) return null;

  return (
    <Box
      position="sticky"
      top={`${90 - top}px`}
      alignSelf="start"
      data-test="favorites-list"
      ml={{ xl: 3 }}
      {...props}
      display={{ base: 'none', xl: 'block' }}
    >
      <Heading variant="h2" fontSize={18} lineHeight={1}>
        Seurantalista
      </Heading>
      {data.entries.slice(0, maxCount).map((favorite) => (
        <FavoriteCard key={favorite.id} {...favorite} bidderId={data.bidderIds.get(favorite.id) ?? null} />
      ))}
      {data.entries.length > maxCount && (
        <Text textAlign="center">
          <StyledLink as={Link} href="/seurantalista">
            Koko seurantalista
          </StyledLink>
        </Text>
      )}
    </Box>
  );
}
