'use client';

import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Box } from '@mezzoforte/forge';
import { Countdown } from '@/components/Countdown/Countdown';

const CountdownOptions = {
  /** garden party campaign 12.6. 00_00 - 14.6. 00:00 */
  startDate: new Date('2024-06-11T23:59:59'),
  endDate: new Date('2024-06-13T23:59:59'),
};

const isEnabled = () => dayjs().isAfter(dayjs(CountdownOptions.startDate));

export default function HeroCountdown() {
  // Ensure Server and client doesn't collide
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  if (isClient && !isEnabled()) return null;
  return <Box background="transparent">{isClient && <Countdown date={CountdownOptions.endDate} />}</Box>;
}
