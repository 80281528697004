import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { FrontPageEntryAPIResponse } from '@/types/ApiResponse';
import { ListEntry } from '@/types/ListEntry';
import { apiToEntry } from '@/util/entry';
import { useApi } from '@/hooks/useApi';

export const useFrontPageEntries = () => {
  const { apiClient } = useApi();

  const fetchFrontPageEntries = async (): Promise<ListEntry[]> =>
    apiClient
      .get<FrontPageEntryAPIResponse[]>('/api/front-page/entries')
      .then((response) => response.data)
      .then((data) => data.map((entry) => apiToEntry(entry)))
      .catch((error: Error | AxiosError) => Promise.reject(error));

  return useQuery({ queryKey: ['entries', 'front-page'], queryFn: fetchFrontPageEntries, staleTime: 1000 * 60 * 5 });
};
