// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from 'next/dynamic';

export const components = {
  entry_page_sidebar_content: dynamic(() => import('./EntryPageSidebarContent')),
  link_blocks: dynamic(() => import('./LinkBlocks')),
  link_list: dynamic(() => import('./LinkList')),
  main_navigation_item: dynamic(() => import('./MainNavigationItem')),
  registration_icon_blocks: dynamic(() => import('./RegistrationIconBlocks')),
  registration_link_block: dynamic(() => import('./RegistrationLinkBlocks')),
};
