import React from 'react';
import { Center, Stack } from '@chakra-ui/react';
import ReactCountdown from 'react-countdown';
import type { CountdownProps as ReactCountdownProps, CountdownRenderProps } from 'react-countdown';
import { DigitContainer } from '@/components/Countdown/DigitContainer';

export interface CountdownProps {
  date?: ReactCountdownProps['date'];
  useLabels?: boolean;
}

export function Countdown({ date, useLabels = true }: CountdownProps) {
  return (
    <ReactCountdown
      date={date}
      renderer={({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
        if (completed) return null;
        return (
          <Center pt={3}>
            <Stack direction="row" spacing={{ base: 2, md: 3 }}>
              <DigitContainer number={days} label="Päivää" {...{ useLabels }} />
              <DigitContainer number={hours} label="Tuntia" {...{ useLabels }} />
              <DigitContainer number={minutes} label="Minuuttia" {...{ useLabels }} />
              <DigitContainer number={seconds} label="Sekuntia" {...{ useLabels }} />
            </Stack>
          </Center>
        );
      }}
    />
  );
}
